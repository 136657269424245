<template>
    <v-container>

        <NavBar :step="7"></NavBar>

        <Titulo></Titulo>

        <br>
        <br>

        <div class="text-center">

            <h4 style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #FFB600; text-transform: uppercase;">¡GRACIAS POR CONFIAR <P style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #FFB600; text-transform: uppercase;"> EN NOSOTROS!</P>
            </h4>

            <!-- <label class="text-center" style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                letter-spacing: -0.01em; color: #2670CA; text-transform:inherit;">{{ userData.name }}, haz contratado <P
                    style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                letter-spacing: -0.01em; color: #2670CA;"> exitosamente tu seguro para mascotas.</P>
            </label> -->

        </div>

        <v-row>

            <v-col class="text-center" style="margin: auto;">

                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto" height="250"
                    style="margin-top:-2%" max-width="400">
                    <v-card-text class=" font-weight-medium mt-12 text-center text-subtitle-1 color:primary">
                    </v-card-text>

                    <v-col class="text-center" v-if="load != false">
                        <label style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                                color: #232323; opacity: 0.75;"><b>Recibirás un email con tu póliza</b> para que así la
                            puedas
                            guardar, imprimir o consultar cuando necesites.</label>
                        <p></p>
                        <label style=" font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px;
                                color: #232323; opacity: 0.75;">La enviaremos a {{ userData.email }}. Revisa tu bandeja
                            de
                            entrada, carpeta de SPAM y Promociones por si acaso.</label>
                    </v-col>
                </v-card>

                <br>

                <br>

                <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 500; font-size: 18px; line-height: 15px;
                    align-items: center; text-align: center; letter-spacing: -0.01em; color: #5B5B5B; margin-left: auto;">
                    ¿Quieres ver otros seguros?
                </h3>

                <br>

                <div class="text-center">
                    <v-btn v-if="screen > 800" style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #FFB600; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white; text-transform: uppercase;"
                        elevation="0" @click="returnPage()">
                        <v-icon color="white" dark left>
                            mdi-arrow-left
                        </v-icon>
                        Volver al home
                    </v-btn>

                    <v-btn v-else
                        style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #FFB600; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white; margin-left: -5%; text-transform: uppercase;"
                        elevation="0" @click="returnPage()">
                        <v-icon color="white" dark left>
                            mdi-arrow-left
                        </v-icon>
                        Volver al home
                    </v-btn>
                </div>

                <br>

            </v-col>

        </v-row>

        <br>

    </v-container>
</template>

<script>
import swal from 'sweetalert';

import Titulo from "../components/Titulo.vue"
import NavBar from "@/components/NavBar.vue";

import CryptoJS from 'crypto-js';
import ShieldService from "../services/shield.service";

export default {
    components: { Titulo, NavBar },
    data() {
        return {
            secretKey: process.env.VUE_APP_CRYPT,

            success: null,
            id: null,
            insurance: null,
            load: false,
            hover: false,

            homeData: null,
            userData: {
                name: null,
                email: null,
            },
            servicesData: null,

            screen: window.screen.width,

            activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
        }
    },
    methods: {
        returnPage() {
            window.location.href = process.env.VUE_APP_REDIRECTION
        }
    },
    async mounted() {
        const query = this.$route.query;
        if (query) {
            if (query.success === "true") {

                const userDataAux = localStorage.getItem('userData');
                const homeDataAux = localStorage.getItem('homeData');
                const servicesDataAux = localStorage.getItem('servicesData');

                if (userDataAux) {
                    const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
                    const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
                    this.userData = JSON.parse(decryptedDataUser);
                    console.log("userData: ", this.userData);
                }

                if (servicesDataAux) {
                    const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
                    const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
                    this.servicesData = JSON.parse(decryptedServicesData);
                    console.log("servicesData: ", this.servicesData);
                }

                if (homeDataAux) {
                    const bytesHomeData = CryptoJS.AES.decrypt(homeDataAux, this.secretKey);
                    const decryptedDataHome = bytesHomeData.toString(CryptoJS.enc.Utf8);
                    this.homeData = JSON.parse(decryptedDataHome);
                    console.log("homeData: ", this.homeData);
                }

                this.success = true;

                if (this.activeCampaingUse == 'TRUE') {
                    await ShieldService.updateContactActiveCampain(null, this.planData, this.servicesData, 3);
                }

                swal({
                    title: "¡Has contratado tu seguro con éxito! ",
                    text: "Revisa tu correo electrónico para obtener toda la información.",
                    icon: "success",
                    button: "Revisar detalle",
                });

                this.load = true

            } else if (query.success === "false") {
                this.success = false;
            }
        }
    },
}
</script>

<style>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}
</style>
